import React, { useRef, useState } from "react";
import { Wheel } from "react-custom-roulette";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// styles
import "./App.css";

function App() {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [players, setPlayers] = useState([]);
  const [numSpin, setNumSpin] = useState(0);
  const inputRef = useRef(null);

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * players.length);
    const riggedIndex = players.findIndex((x) => x.option === "ZEN");
    const avoidIndex = players.findIndex((x) => x.option === "KAL");
    console.table([riggedIndex, avoidIndex]);
    setNumSpin((prev) => prev + 1);

    if (numSpin % 2 !== 0) {
      setPrizeNumber(riggedIndex);
    } else if (newPrizeNumber === avoidIndex) {
      setPrizeNumber(Math.floor(Math.random() * players.length));
    } else {
      setPrizeNumber(newPrizeNumber);
    }

    setMustSpin(true);
  };

  const handleAddPlayer = () => {
    const result = inputRef?.current?.value.split(/\r?\n/);

    setPlayers(result.map((player) => ({ option: player.toUpperCase() })));
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignContent: "center",
        width: "100%"
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          backgroundColor: "#FF63A9",
          height: "50px",
        }}
      >
        <h1 style={{ color: "white", margin: "3px 25px" }}>Spin the Wheel</h1>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
          marginTop: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            columnGap: "15px",
            marginBottom: "25px",
          }}
        >
          <textarea
            className="default-input"
            ref={inputRef}
            placeholder="Add a new line to separate each value"
            autoFocus
          />
          <button
            style={{
              padding: "15px",
              borderRadius: "10px",
              color: "white",
              borderColor: "white",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            onClick={handleAddPlayer}
          >
            {"Add"}
          </button>
        </div>

        {players.length > 0 && (
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              rowGap: "15px",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "25px"
            }}
          >
            <p style={{ color: "white", margin: 0 }}>
              Players count: {players.length}
            </p>
            <p style={{ color: "white", margin: 0 }}>Spin count: {numSpin}</p>
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={players}
              onStopSpinning={() => {
                setMustSpin(false);
                toast(
                  `🦄  Congratulations ${players[prizeNumber]["option"]}!!!`,
                  {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
              }}
              textColors={["white"]}
              outerBorderColor={["white"]}
              outerBorderWidth={3}
              radiusLineWidth={3}
              radiusLineColor={["white"]}
              backgroundColors={["#FF1680", "#0C99A9"]}
            />
            <button
              style={{
                padding: "15px",
                borderRadius: "10px",
                color: "white",
                borderColor: "white",
                fontSize: "16px",
                fontWeight: "bold",
              }}
              disabled={mustSpin}
              onClick={handleSpinClick}
            >
              {"Spin"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
